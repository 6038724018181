import React from "react";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import PropTypes from "prop-types";


const CaseColumn = ({ 
  title,
  date,
  location,
  featuredImage,
  altText,
  pageLink
}) => {
  return (
    <div className="mb-8 lg:mb-0">
      <BackgroundImage
        className="w-full mb-8 lg:mb-0"
        fluid={ featuredImage }
        alt={ altText }
        backgroundColor={ `#142538` }
      >
        <Link
          to={ pageLink }
        >
          <div className="flex flex-col justify-between h-72 p-6 font-thin text-white">
            <h3 className="text-2xl">
              <span>{ title }</span>
            </h3>
            <div>
              <p>{ location }</p>
              <p>Commencement: { date }</p>
            </div>
          </div>
        </Link>
      </BackgroundImage>
    </div>
  )
}

CaseColumn.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  location: PropTypes.string,
  description: PropTypes.string,
  featuredImage: PropTypes.object,
  pageLink: PropTypes.string,
}

CaseColumn.defaultProps = {
  title: '',
  date: '',
  location: '',
  description: '',
  featuredImage: {},
  pageLink: '', 
}

export default CaseColumn;
