import React from "react";

import CallToAction from "../../components/CallToAction/CallToAction";
import CaseGrid from "../../components/Grid/Cases/CaseGrid";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Layout from "../../components/Layout/Layout";


const ClassActionsPage = () => {
  return (
    <Layout
      title="Active Cases"
      description="At KND, we bring together groups of individuals and small businesses to create a
      powerful “Class” that has the size and resources needed to level the playing field and
      stand up to big corporations that take advantage of their rights."
    >
      <Header
        colorScheme="light"
      />

      <Hero
        title="Class Actions"
        subtitle="For an individual or small business, the thought of taking on a large corporation in court
        can feel hopeless. At KND, we bring together groups of individuals and small businesses to create a
        powerful “Class” that has the size and resources needed to level the playing field and
        stand up to big corporations that take advantage of their rights."
        contactInfo={ false }
        colorScheme="light"
      />

      <CaseGrid
        heading="Active Class Actions"
      />

      <CallToAction />

      <Footer />
    </Layout>
  );
}

export default ClassActionsPage;